import React from "react"
import Layout from "../components/layout"
import Styles from "./css/policies.module.css"
import { Helmet } from "react-helmet"

const ReturnPolicy = () => (
  <Layout>
    <Helmet>
      <title>Return Policy - Colorful Phone Cases</title>
      <meta name="description" content="Return policy for colorful phone cases" />
    </Helmet>
    <div className={Styles.wrapper}>
      <div className={Styles.mainDiv}>
        <h1 className={Styles.pageHeader}>Return Policy</h1>
        <p>
          We currently do not accept returns or exchanges. However, If your case is damaged on arrival or lost in shipping please contact us as soon as possible.
          You can contact us at colorfulphonecasesinfo@gmail.com.
        </p>
      </div>
    </div>
  </Layout>
)

export default ReturnPolicy